import { ResumableFileUploader } from "../utils/resumable_file_uploader";

import { apiPost } from "./_server";
import { UploadFileAdminRequest, UploadResponse } from "./models/upload";

export async function uploadFile(file: File): Promise<UploadResponse> {
  const formData = new FormData();
  formData.append("file", file);

  return apiPost({ path: "/upload", data: formData });
}

export async function uploadFileDirectlyToBucket(file: File): Promise<UploadResponse> {
  const uploader = new ResumableFileUploader(file);

  return uploader.upload();
}

export async function uploadFileAdmin(payload: UploadFileAdminRequest) {
  const formData = new FormData();
  formData.append("file", payload.file);

  return apiPost({ path: `/upload/admin/${payload.relatedUserId}`, data: formData });
}
